$(document).ready(function () {


    $('.events-list .read-more').on('click', function () {


        $(this).parent().next('.event-content-full').css("display", "block");
        $(this).parent('.event-content-truncated').css("display", "none");
        $(this).attr('data-status', 'open');


    });

    $('.events-list .read-less').on('click', function () {


        $(this).parent('.event-content-full').css('display', 'none');
        $(this).parent().prev('.event-content-truncated').css("display", "block");
        $('.events-list .read-more').attr('data-status', '');


    });


    $(".header-container").sticky({topSpacing: 0});

    $(".sticky-footer").stickyFooter({
        content: '.wrapper',
        frame: '.main'
    });
    // jQuery.fn.center = function(parent) {
    //     if (parent) {
    //         parent = this.parent();
    //     } else {
    //         parent = window;
    //     }
    //     this.css({
    //         "position": "absolute",
    //         "top": ((($(parent).height() - this.outerHeight()) / 2) + $(parent).scrollTop() + "px"),
    //         "left": ((($(parent).width() - this.outerWidth()) / 2) + $(parent).scrollLeft() + "px")
    //     });
    //     return this;
    // }


    //Make sure map is full viewport height
    function setMapContainerSize() {
        var mWidth = $(window).width();
        var mHeight = ((mWidth * 0.9) / 2) + 80;
        $('.map-container').height(mHeight);
        $('.map-container').width(mWidth);
    }

    function highlightRegion(index) {
        if (index == 0)
            return false;

        index--;

        var region = $('.map-region')[index];
        setTimeout(function () {
            $(region).mouseover();
            setTimeout(function () {
                $(region).mouseleave();
                highlightRegion(index);
            }, 500);
        }, 50);
    }

    $('img[usemap]').rwdImageMaps();

    $('.map-region').mouseover(function () {
        var region = $(this).data('region');
        $('.map-container .map.' + region).css('opacity', 1);
    });
    $('.map-region').mouseleave(function () {
        var region = $(this).data('region');
        $('.map-container .map.' + region).css('opacity', 0);
    });


    setMapContainerSize();

    $(window).on('load resize', function () {
        setMapContainerSize();
    });

    $(window).on('load', function () {
        setTimeout(function () {
            highlightRegion($('.map-region').length);
        }, 500);
    });


    $('.back-to-top').on('click', function (e) {
        e.preventDefault();
        $('html,body').animate({
            scrollTop: 0
        }, 500);
    });


    //Tidy up pagination current page
    var currentPageListItem = $('.pagination .current-page');
    currentPageListItem.width(currentPageListItem.height());

    $('.testimonials-slider').slick({
        dots: false,
        autoplay: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: false,
        nextArrow: false,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });

    $('.block__slider').each(function () {
        var showArrows = $(this).data('show-arrows');
        $(this).slick({
            dots: true,
            autoplay: true,
            infinite: true,
            speed: 300,
            nextArrow: showArrows ? '<button type="button" class="slick-next slick-arrow" role="button" ></button>' : false,
            prevArrow: showArrows ? '<button type="button" class="slick-prev slick-arrow" role="button" ></button>' : false,
            slidesToShow: $(this).data('max-slides'),
            slidesToScroll: 1,
            adaptiveHeight: false,
            autoplaySpeed: 3000,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    });

    $('.dropdown-toggle').dropdown();

    $('select:not(.select2disabled)').select2({
        placeholder: '-- Please Select --'
    });


    /* custom select box ui */
    var x, i, j, selElmnt, a, b, c;
    /* Look for any elements with the class "custom-select": */
    x = document.getElementsByClassName("custom-select-ui");
    for (i = 0; i < x.length; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];

    /* For each element, create a new DIV that will act as the selected item: */
    a = document.createElement("DIV");
    a.setAttribute("class", "select-selected");
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    x[i].appendChild(a);
    /* For each element, create a new DIV that will contain the option list: */
    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");
    for (j = 1; j < selElmnt.length; j++) {
        /* For each option in the original select element,
        create a new DIV that will act as an option item: */
        c = document.createElement("DIV");
        c.innerHTML = selElmnt.options[j].innerHTML;
        if(selElmnt.options[j].hasAttribute('disabled')) continue;
        c.addEventListener("click", function(e) {
            /* When an item is clicked, update the original select box,
            and the selected item: */
            var y, i, k, s, h;
            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
            h = this.parentNode.previousSibling;
            console.log(h, s);
            for (i = 0; i < s.length; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;
                $(s).trigger('change');
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("same-as-selected");
                for (k = 0; k < y.length; k++) {
                y[k].removeAttribute("class");
                }
                this.setAttribute("class", "same-as-selected");
                break;
            }
            }
            h.click();
        });
        b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener("click", function(e) {
        /* When the select box is clicked, close any other select boxes,
        and open/close the current select box: */
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");
    });
    }

    function closeAllSelect(elmnt) {
        /* A function that will close all select boxes in the document,
        except the current select box: */
        var x, y, i, arrNo = [];
        x = document.getElementsByClassName("select-items");
        y = document.getElementsByClassName("select-selected");
        for (i = 0; i < y.length; i++) {
            if (elmnt == y[i]) {
                arrNo.push(i)
            } else {
                y[i].classList.remove("select-arrow-active");
            }
        }
        for (i = 0; i < x.length; i++) {
            if (arrNo.indexOf(i)) {
                x[i].classList.add("select-hide");
            }
        }
    }

    /* If the user clicks anywhere outside the select box,
    then close all select boxes: */
    document.addEventListener("click", closeAllSelect);

});

$(window).load(function () {


    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#ea5181",
                "text": "#ffffff",
            },
            "button": {
                "background": "#fff",
                "text": "#ea5181"
            }
        },
        "content": {
            "message": "We use cookies on our website to enhance your browsing experience. By continuing to use this site without changing your cookie settings, you are consenting to our use of cookies. To understand more about how we use cookies, or to change your browser settings, please see our ",
            "dismiss": "Accept",
            "link": "Cookie Policy.",
            "href": "/cookie-policy"
        },
        "position": "top",
        "static": true
    });

    setTimeout(function () {
        $('.teams-list').each(function () {
            var $columns = $('article .inner', this);
            var maxHeight = Math.max.apply(Math, $columns.map(function () {
                return $(this).outerHeight(true);
            }).get());

            console.log(maxHeight)

            $columns.parent().height(maxHeight).css('padding-bottom', 0);
        });
    }, 1);


    // // bind filter on select change
    $('.filters-select .dropdown-item').click(function () {
        var filter = $(this).data('filter');

        if (filter === '*') {
            $('.testimonial').show();
        } else {
            $('.testimonial').each(function () {
                if ($(this).hasClass(filter)) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        }

        $('.dropdown-toggle .currently-selected').html($(this).html());
    });
});